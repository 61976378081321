let isThirdPartyAmp = false;

export const setIsThirdPartyAmp = (newValue: boolean) => {
  isThirdPartyAmp = newValue;
};

export const getIsThirdPartyAmp = () => isThirdPartyAmp;

export const requestAmpResize = () => {
  try {
    if (!window.parent) {
      return;
    }

    if (window.location.search.match(/renderer=amp/) || isThirdPartyAmp) {
      setTimeout(() => {
        window.parent.postMessage(
          {
            height: document.body.scrollHeight,
            sentinel: 'amp',
            type: 'embed-size'
          },
          '*'
        );
      }, 500);
    }

    if (window.location.search.match(/renderer=metro/)) {
      requestAnimationFrame(() => {
        const docHeight = document.documentElement.offsetHeight;
        const message = {
          docHeight,
          iframe: window.name
        };

        window.top.postMessage(JSON.stringify(message), '*');
      });
    }
  } catch (error) {
    // Ignore error
  }
};

interface PageCriteria {
  geo?: string;
  pageType: string;
  darkMode?: boolean;
  region?: string;
}

export interface DMWindow extends Window {
  PageCriteria?: PageCriteria;
}

export const getPageCriteria = () => {
  const pageCriteria = (window as DMWindow).PageCriteria;

  return pageCriteria || ({} as PageCriteria);
};

export const getTimestampQsSuffix = (timestamp?: number) => `&fromXPTS=${timestamp || Date.now()}`;
