import axios from 'axios';
import {RTA3, RTA3Page} from '@mol-rta/rta3-www';

import {getIsThirdPartyAmp} from '../helpers';

interface TrackBidmaxArgs {
  event: 'impression' | 'viewable' | 'abtimeout' | 'error' | 'click';
  version: string;
  itoCode?: string;
  channel?: string;
  platform: string;
  startTab?: string;
  geoResults?: string;
  environment?: string;
  abNumber?: string | null;
  module?: string;
  thirdpartyPlatform?: string;
  publishedDate?: string;
}

export const BIDMAX_TRACKING_ENABLED = true;

const POST_XHR_URL = 'https://crta.dailymail.co.uk';
const NEWZIT_POST_XHR_URL = 'https://crta.newzit.com';

const startTime = Date.now();

let bidmaxPublisherUrl: string | null = null;

export const setBidmaxPublisherUrl = (newUrl: string) => {
  bidmaxPublisherUrl = newUrl;
};

const postEventToWindow = (args: TrackBidmaxArgs) => {
  const params = {
    ...args,
    module: 'newzit'
  };

  delete params.channel;
  window.postMessage('__bidmax\n' + JSON.stringify(params), '*');
};

interface BidmaxBody {
  event_type: string;
  payload: TrackBidmaxArgs;
  referrer: string;
  timeAtSend: number;
  timestamp: number;
  type: string;
  type_version: number;
  version: string;
  url?: string;
}

const getBodyFromArgs = (args: TrackBidmaxArgs) => {
  const body: BidmaxBody = {
    event_type: 'bidmax',
    payload: {
      ...args,
      module: 'newzit'
    },
    referrer: document.referrer,
    timeAtSend: Date.now() - startTime,
    timestamp: Date.now(),
    type: 'custom',
    type_version: 2,
    version: args.version
  };

  if (args.platform === 'thirdparty') {
    const isThirdPartyIframe = getIsThirdPartyAmp();

    body.payload.thirdpartyPlatform = isThirdPartyIframe ? 'iframe' : 'web';

    if (bidmaxPublisherUrl) {
      body.url = bidmaxPublisherUrl;
    } else {
      let pageUrl = location.href;

      try {
        const linkRelCanonical = document.querySelector('link[rel="canonical"]');

        if (linkRelCanonical && linkRelCanonical.getAttribute('href')) {
          pageUrl = linkRelCanonical.getAttribute('href') as string;
        }
      } catch (error) {
        // ignore
      }

      body.url = pageUrl;
    }

    try {
      const metaPublished = document.querySelector('meta[property="article:published_time"]');
      const publishedDate = metaPublished && metaPublished.getAttribute('content');

      if (publishedDate) {
        body.payload.publishedDate = publishedDate;
      }
    } catch (error) {
      // Ignore
    }
  }

  return body;
};

type RTAEventBody = Omit<TrackBidmaxArgs, ''>;
let rtaPage: RTA3Page<RTAEventBody> | null = null;

const postEventToUrl = async (args: TrackBidmaxArgs) => {
  try {
    const body = getBodyFromArgs(args);

    const useNewzitDomainCrta = args.platform === 'thirdparty';

    await axios.post(useNewzitDomainCrta ? NEWZIT_POST_XHR_URL : POST_XHR_URL, body, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  } catch (error) {
    // Ignore, prevent uncaught
  }
};

const postEventToRTA = async (args: TrackBidmaxArgs) => {
  try {
    const rtaEvent: TrackBidmaxArgs = {
      ...args,
      module: 'newzit'
    };

    const isThirdPartyIframe = getIsThirdPartyAmp();
    const isWebPlatform =
      args.platform === 'thirdparty'
        ? !isThirdPartyIframe
        : ['mol.web.mobile', 'mol.web.desktop'].includes(args.platform);

    if (rtaEvent.event === 'impression') {
      // Report a new impression
      rtaPage = RTA3<RTAEventBody>(
        rtaEvent.environment === 'production' ? 'https://rta2.newzit.com' : 'https://rta2.dailymailint.co.uk',
        'NewzitXP',
        rtaEvent,
        isWebPlatform ? document.referrer : rtaEvent.itoCode || document.referrer,
        bidmaxPublisherUrl || window.location.href
      );
    } else if (rtaPage) {
      rtaPage.event(rtaEvent);
    }
  } catch (error) {
    // Ignore, prevent uncaught
  }
};

const tracked: string[] = [];

export const trackBidmax = (args: TrackBidmaxArgs) => {
  if (!BIDMAX_TRACKING_ENABLED) {
    return;
  }

  try {
    if (args.platform === 'mol.native.android' || args.platform === 'mol.native.ios') {
      if (tracked.includes(args.event)) {
        return;
      }

      tracked.push(args.event);
    }

    if ('adverts' in window && 'DM' in window && args.platform !== 'thirdparty') {
      postEventToWindow(args);
    } else {
      postEventToUrl(args).catch();
    }

    postEventToRTA(args).catch();
  } catch (error) {
    // Ignore
  }
};
