const STORAGE_PREFIX = 'mol-fe-xpmodule-newzit-';

type SerializableType = string | boolean | number | null;

export const setToStorage = (key: string, value: SerializableType) => {
  try {
    if ('localStorage' in window) {
      window.localStorage.setItem(`${STORAGE_PREFIX}${key}`, JSON.stringify(value));
    }
  } catch (error) {
    // Ignore failures
  }
};

export const getFromStorage = (key: string): SerializableType => {
  try {
    if ('localStorage' in window) {
      const rawValue = window.localStorage.getItem(`${STORAGE_PREFIX}${key}`);

      if (rawValue) {
        return JSON.parse(rawValue);
      }

      return null;
    }
  } catch (error) {
    // Ignore failures
  }

  return null;
};
