import URL from 'url-parse';

import {getUserCountry, loadABframe, GeoCountry, TopStoriesCategory, getAbInfo, getRelevantFirstOverride} from './api';
import {getPageCriteria, setIsThirdPartyAmp} from './helpers';
import {getFromStorage, setToStorage} from './storage';
import {setBidmaxPublisherUrl} from './api/bidmax';
import {AppProps, Platform} from './components/App';

const DEFAULT_LIMIT = 5;
const DEFAULT_LIMIT_THIRDPARTY = 7;
const DEFAULT_LIMIT_COMPACT = 1;
const ITO_BY_PLATFORM: {[key: string]: string} = {
  'mol.native.android': 'MOL_Andr_Module',
  'mol.native.ios': 'MOL_ios_module',
  thirdparty: 'thirdparty'
};
const AU_ENABLED_PROD = false;
const NY_TAB_STATES = ['NY', 'NJ', 'CT'];

export const getPropsFromContext = async (element: HTMLDivElement): Promise<AppProps> => {
  const pageCriteria = getPageCriteria();
  let isAmp = false;
  let isFBIARenderer = false;
  let debugAB = false;
  let clearAB = false;

  try {
    isAmp = Boolean(window.location.search.match(/renderer=amp/));
    isFBIARenderer = Boolean(window.location.search.match(/renderer=fbia/));
    debugAB = Boolean(window.location.search.match(/newzitDebugAB=true/));
    clearAB = Boolean(window.location.search.match(/newzitClearAB=true/));
  } catch (error) {
    // Ignore
  }

  let apiHost = element.dataset.apiHost;
  let platform = element.dataset.xpmodulePlatform;
  let geoStr: string | undefined = element.dataset.geo;
  let startTabStr: string | undefined = element.dataset.startTab;
  let limitStr: number | undefined;
  let headlineStr: string | undefined = element.dataset.headline;
  let previewTextStr: string | undefined = element.dataset.previewText;
  let adSlotStr: string | undefined = element.dataset.adSlot;
  let forceItoStr: string | undefined = element.dataset.forceIto;
  let enlargeFirstStr: string | undefined = element.dataset.enlargeFirst;
  let referringDomainStr: string | undefined = element.dataset.referringDomain;
  let referringURLStr: string | undefined = element.dataset.referringURL;
  let environmentStr: string | undefined = element.dataset.environment;
  let versionStr: string | undefined = element.dataset.version;
  let channelStr: string | undefined = element.dataset.channel;
  let articleIdStr: string | undefined = element.dataset.articleId;
  let darkMode: boolean =
    (element as HTMLDivElement).dataset.darkMode === 'true' || Boolean(pageCriteria && pageCriteria.darkMode);
  const useNewzitGeoEndpoint: boolean = element.dataset.useNewzitGeoEndpoint === 'true' || platform === 'thirdparty';
  let publisher: string | undefined = element.dataset.publisher;
  let compactMode: boolean = (element as HTMLDivElement).dataset.compactMode === 'true';

  if (element.dataset.limit) {
    limitStr = parseInt(element.dataset.limit as string, 10);
  }

  if (!apiHost && platform === 'iframe') {
    try {
      const parsedUrl = new URL(window.location.href, true);

      apiHost = parsedUrl.query && parsedUrl.query.apiHost;

      if (!apiHost) {
        apiHost = 'https://www.newzit.com/api';
      }
    } catch (error) {
      // Ignore
    }
  }

  if (platform === 'thirdparty' && !element.dataset.debugThirdParty) {
    apiHost = 'https://www.newzit.com/api';
  }

  if (!apiHost) {
    throw new Error('No api host set for xpmodule-news-search');
  }

  if (platform === 'iframe') {
    if (isAmp) {
      platform = 'mol.amp';
    } else if (isFBIARenderer) {
      platform = 'mol.fbia';
    }

    const parsedUrl = new URL(window.location.href, true);

    if (!geoStr) {
      geoStr = parsedUrl.query && parsedUrl.query.geo;
    }

    if (!startTabStr) {
      startTabStr = parsedUrl.query && parsedUrl.query.startTab;
    }

    if (!limitStr && parsedUrl.query && parsedUrl.query.limit) {
      limitStr = parseInt(parsedUrl.query.limit, 10);
    }

    if (!headlineStr) {
      headlineStr = parsedUrl.query && parsedUrl.query.headline;
    }

    if (!previewTextStr) {
      previewTextStr = parsedUrl.query && parsedUrl.query.previewText;
    }

    if (!adSlotStr) {
      adSlotStr = parsedUrl.query && parsedUrl.query.adSlot;
    }

    if (!forceItoStr) {
      forceItoStr = parsedUrl.query && parsedUrl.query.forceIto;
    }

    if (!referringDomainStr) {
      referringDomainStr = parsedUrl.query && parsedUrl.query.referringDomain;
    }

    if (!referringURLStr) {
      referringURLStr = parsedUrl.query && parsedUrl.query.referringURL;
    }

    if (!enlargeFirstStr) {
      enlargeFirstStr = parsedUrl.query && parsedUrl.query.enlargeFirst;
    }

    if (!environmentStr) {
      environmentStr = parsedUrl.query && parsedUrl.query.environment;
    }

    if (!versionStr) {
      versionStr = parsedUrl.query && parsedUrl.query.version;
    }

    if (!channelStr) {
      channelStr = parsedUrl.query && parsedUrl.query.channel;
    }

    if (!darkMode) {
      darkMode = parsedUrl.query && parsedUrl.query.darkMode === 'true';
    }

    if (!compactMode) {
      compactMode = parsedUrl.query && parsedUrl.query.compactMode === 'true';
    }

    if (!articleIdStr) {
      articleIdStr = parsedUrl.query && parsedUrl.query.articleId;
    }
  }

  let abNumber: string | null = null;

  try {
    const abNumberFromStorage = getFromStorage('abNumber');

    if (
      !clearAB &&
      abNumberFromStorage &&
      typeof abNumberFromStorage === 'string' &&
      abNumberFromStorage.match(/^\d+$/)
    ) {
      abNumber = abNumberFromStorage;
    } else {
      abNumber = await loadABframe(environmentStr || 'production', {
        channel: channelStr,
        platform: platform as Platform,
        version: versionStr
      });
      setToStorage('abNumber', abNumber);
    }

    if (abNumber) {
      apiHost += `/${abNumber}`;
    }
  } catch (errFrame) {
    // Ignore
  }

  const adSlot =
    adSlotStr && ['mpu', 'bellyband', 'mpuSmall'].includes(adSlotStr)
      ? (adSlotStr as 'mpu' | 'bellyband' | 'mpuSmall')
      : undefined;

  let geo: GeoCountry;
  let startTab: TopStoriesCategory = 'news';
  let isNY = false;

  if (!geoStr || geoStr === 'auto') {
    let isUS = pageCriteria && pageCriteria.geo && pageCriteria.geo.toLowerCase() === 'us';
    let isAU = pageCriteria && pageCriteria.geo && pageCriteria.geo.toLowerCase() === 'au';

    isNY = Boolean(isUS && NY_TAB_STATES.includes(pageCriteria.region || ''));

    try {
      if (
        (platform === 'mol.native.ios' || platform === 'mol.native.android' || !pageCriteria || !pageCriteria.geo) &&
        !adSlot
      ) {
        const geoData = await getUserCountry(useNewzitGeoEndpoint);

        if (geoData) {
          if (geoData.Country.toLowerCase() === 'us') {
            isUS = true;

            if (NY_TAB_STATES.includes(geoData.REGION_CODE)) {
              isNY = true;
            }
          }

          if (geoData.Country.toLowerCase() === 'au') {
            isAU = true;
          }
        }
      }
    } catch (error) {
      // Ignore
    }

    geo = isUS ? 'us' : 'gb';
    geo = isAU ? 'au' : geo;
  } else {
    geo = ['gb', 'us', 'au'].includes(geoStr) ? (geoStr as GeoCountry) : 'gb';
    isNY = geo === 'us' && pageCriteria && NY_TAB_STATES.includes(pageCriteria.region || '');
  }

  if (startTabStr) {
    startTab = ['news', 'entertainment', 'sport', 'newYork'].includes(startTabStr)
      ? (startTabStr as TopStoriesCategory)
      : 'news';
  }

  if (!isNY && startTab === 'newYork') {
    startTab = 'news';
  }

  let limit: number =
    limitStr ||
    (compactMode && DEFAULT_LIMIT_COMPACT) ||
    (platform === 'thirdparty' && DEFAULT_LIMIT_THIRDPARTY) ||
    DEFAULT_LIMIT;
  const isThirdPartyIframe = platform === 'thirdparty' && element.dataset.thirdpartyIframe;

  if (platform === 'thirdparty' && !publisher) {
    if (isThirdPartyIframe) {
      try {
        const parsedUrl = new URL(window.location.href, true);

        publisher = parsedUrl.query && parsedUrl.query.publisher;

        compactMode = compactMode || (parsedUrl.query && parsedUrl.query.compactMode === 'true');

        if (compactMode && !limitStr) {
          limit = DEFAULT_LIMIT_COMPACT;
        }
      } catch (error) {
        // Ignore
      }
    }

    if (!publisher) {
      publisher = 'reach';
    }
  }

  if (platform === 'thirdparty') {
    if (isThirdPartyIframe) {
      setIsThirdPartyAmp(true);
      if (environmentStr === 'integration') {
        apiHost = 'https://www.newzitint.com/api';
      }
    }

    const compactPrefix = compactMode ? 'C' : '';

    if (!forceItoStr) {
      try {
        const parsedUrl = new URL(window.location.href, true);

        if (isThirdPartyIframe) {
          const publisherUrl = parsedUrl.query && parsedUrl.query.publisherUrl;
          let publisherDomain = (parsedUrl.query && parsedUrl.query.publisherDomain) || null;

          if (publisherUrl) {
            setBidmaxPublisherUrl(publisherUrl);
          }

          if (publisherUrl && !publisherDomain) {
            try {
              const parsedPublisherUrl = new URL(publisherUrl);

              if (parsedPublisherUrl.protocol === 'http:' || parsedPublisherUrl.protocol === 'https:') {
                publisherDomain = parsedPublisherUrl.hostname;
              }
            } catch (error) {
              // Ignore
            }
          }

          forceItoStr = publisherDomain
            ? `XP${compactPrefix}-amp-${publisherDomain}`
            : `thirdparty${compactPrefix}-amp-${publisher}`;

          referringDomainStr = publisherDomain || undefined;
          referringURLStr = publisherUrl;
        } else {
          if (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') {
            forceItoStr = `XP${compactPrefix}-${parsedUrl.hostname}`;
          }
        }
      } catch (error) {
        // Could fail if module is embedded wrongly, ignore and continue
      }
    }

    if (!forceItoStr) {
      forceItoStr = `thirdparty${compactPrefix}-${publisher}`;
    }
  }

  if (!forceItoStr && platform && ITO_BY_PLATFORM[platform]) {
    forceItoStr = ITO_BY_PLATFORM[platform];
  }

  if (!forceItoStr && platform === 'mol.web.mobile') {
    forceItoStr = 'MOL_Mweb_Module';

    if (pageCriteria && pageCriteria.pageType === 'article') {
      forceItoStr = 'MOL_Mweb_Module_Article';
    }
  }

  const enlargeFirst = enlargeFirstStr === 'true';
  const enlargeFirstDisabled = enlargeFirstStr === 'false';

  // Force tab to showbiz on mobile/thirdparty
  if (
    (enlargeFirst || platform === 'thirdparty') &&
    (!startTab || startTab === 'news') &&
    !(platform === 'thirdparty' && startTabStr) &&
    channelStr !== 'news'
  ) {
    startTab = 'entertainment';
  }

  if (platform === 'mol.fbia' && startTab === 'entertainment' && channelStr === 'news') {
    startTab = 'news';
  }

  if (
    !referringDomainStr &&
    !referringURLStr &&
    (platform === 'mol.web.desktop' || platform === 'mol.web.mobile' || platform === 'thirdparty') &&
    !isThirdPartyIframe
  ) {
    try {
      const parsedUrl = new URL(window.location.href, true);

      if (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') {
        referringDomainStr = parsedUrl.host;
        referringURLStr = parsedUrl.href;
      }
    } catch (error) {
      // Could fail if module is embedded wrongly, ignore and continue
    }
  }

  let relevantFirstOverride = null;
  const articleId = articleIdStr && parseInt(articleIdStr, 10);

  if (platform === 'mol.amp' && articleId) {
    try {
      const abIdsResponse = await getAbInfo(apiHost);

      if (abIdsResponse && abIdsResponse.molABTest && abIdsResponse.molABTest.includes('AMP')) {
        relevantFirstOverride = await getRelevantFirstOverride(articleId, geo, environmentStr || 'production');
      }
    } catch (error) {
      // Ignore failure
    }
  }

  const environment = environmentStr || 'production';

  if (geo === 'au' && environment === 'production' && !AU_ENABLED_PROD) {
    geo = 'gb';
  }

  const props: AppProps = {
    abNumber,
    adSlot,
    apiHost,
    channel: channelStr,
    compactMode,
    darkMode,
    debugAB,
    enlargeFirst,
    enlargeFirstDisabled,
    environment,
    forceIto: forceItoStr,
    geo,
    headline: headlineStr || undefined,
    isFBIARenderer,
    limit,
    platform: platform as Platform,
    previewText: previewTextStr || undefined,
    publisher: publisher || null,
    referringDomain: referringDomainStr,
    referringURL: referringURLStr,
    relevantFirstOverride,
    showNYTab: isNY,
    startTab,
    version: versionStr || XPMODULE_VERSION
  };

  return props;
};
