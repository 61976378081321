import React, {useState, useEffect, useRef} from 'react';
import classnames from 'classnames';

import {getResultsByGeoAndTab, DataResult, GeoCountry, TopStoriesCategory} from '../../api';
import {trackBidmax, BIDMAX_TRACKING_ENABLED} from '../../api/bidmax';
import {getTimestampQsSuffix, requestAmpResize} from '../../helpers';
import {ResultItem} from '../ResultItem';
import {Spinner} from '../Spinner';

import styles from './styles.css';
import LogoImage from './assets/logo.png';
import LogoImageRetina from './assets/logo2x.png';
import LogoImageWide from './assets/logoWide.png';
import LogoImageWideRetina from './assets/logoWide2x.png';

export type Platform =
  | 'cc'
  | 'mol.web.desktop'
  | 'mol.web.mobile'
  | 'mol.fbia'
  | 'mol.amp'
  | 'mol.native.android'
  | 'mol.native.ios'
  | 'iframe'
  | 'thirdparty';

export interface AppProps {
  apiHost: string;
  breakpoint?: 'narrow' | 'wide';
  geo: GeoCountry;
  startTab: TopStoriesCategory;
  limit: number;
  platform: Platform;
  useSmallTabs?: boolean;
  headline?: string;
  previewText?: string;
  adSlot?: 'mpu' | 'mpuSmall' | 'bellyband';
  forceIto?: string;
  enlargeFirst?: boolean;
  enlargeFirstDisabled?: boolean;
  referringDomain?: string;
  referringURL?: string;
  environment?: string;
  darkMode?: boolean;
  version?: string;
  channel?: string;
  abNumber?: string | null;
  showNYTab?: boolean;
  relevantFirstOverride?: DataResult | null;
  isFBIARenderer?: boolean;
  publisher?: string | null;
  debugAB?: boolean;
  compactMode?: boolean;
}

const REFRESH_INTERVAL = 5 * 60 * 1000;

const getTabDestinationSuffix = (tab: TopStoriesCategory) => `&startTab=${tab}`;

interface CategoryDisplay {
  label: string;
  value: TopStoriesCategory;
}

const categories: CategoryDisplay[] = [
  {label: 'Top Stories', value: 'news'},
  {label: 'Showbiz', value: 'entertainment'},
  {label: 'Sport', value: 'sport'},
  {label: 'New York', value: 'newYork'}
];

const adSlotItoPrefixes = {
  bellyband: 'bellyband',
  mpu: 'dmpu',
  mpuSmall: 'mpu'
};

export const App = ({
  apiHost,
  breakpoint = 'narrow',
  geo,
  limit,
  platform,
  startTab,
  useSmallTabs = false,
  headline,
  previewText,
  adSlot,
  forceIto,
  enlargeFirst,
  referringDomain,
  referringURL,
  environment,
  darkMode,
  version = 'unknown',
  channel,
  abNumber = null,
  showNYTab = false,
  relevantFirstOverride = null,
  isFBIARenderer = false,
  enlargeFirstDisabled = false,
  publisher = null,
  debugAB = false,
  compactMode = false
}: AppProps) => {
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [resultsState, setResultsState] = useState<DataResult[] | null>(null);
  const [currentTab, setCurrentTab] = useState<TopStoriesCategory>(startTab);
  const [timestamp, setTimestamp] = useState(Date.now());
  const mode = headline || previewText ? 'article' : 'promo';
  const mobileStyle = !adSlot && platform !== 'mol.web.desktop' && platform !== 'cc';
  const appRef = useRef<HTMLDivElement>(null);

  const loadResults = async (tab: TopStoriesCategory) => {
    setLoading(true);
    setError(null);

    try {
      const newResults = await getResultsByGeoAndTab(geo, tab, apiHost, isFBIARenderer, publisher);

      if (newResults) {
        setResultsState(newResults);
        requestAmpResize();
      }
    } catch (error) {
      setError(error);
      trackBidmax({
        abNumber,
        channel,
        environment,
        event: 'error',
        geoResults: geo,
        itoCode,
        platform,
        startTab,
        version
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    loadResults(currentTab).catch();

    const interval = setInterval(() => {
      loadResults(currentTab).catch();
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [currentTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const results = relevantFirstOverride && resultsState ? [relevantFirstOverride, ...resultsState] : resultsState;

  const logoSrc = breakpoint === 'wide' ? LogoImageWide : LogoImage;
  const logoSrcRetina = breakpoint === 'wide' ? LogoImageWideRetina : LogoImageRetina;
  let itoCode = mode === 'article' ? 'Mail-Top-Stories-Article' : 'Mail-Top-Stories';

  if (adSlot) {
    itoCode = `${adSlotItoPrefixes[adSlot]}_${headline ? 'headline' : 'noheadline'}`;
  }

  if (forceIto) {
    itoCode = forceIto;
  }

  let isFirstEnlarged = enlargeFirst;

  if (platform === 'thirdparty') {
    if (enlargeFirstDisabled || compactMode) {
      isFirstEnlarged = false;
    } else if (useSmallTabs) {
      isFirstEnlarged = true;
    }
  }

  if (isFirstEnlarged && platform !== 'thirdparty') {
    itoCode += '_Largethumbnail';
  }

  const manualReferrer =
    referringDomain && referringURL
      ? `&referringDomain=${encodeURIComponent(referringDomain)}&referringURL=${encodeURIComponent(referringURL)}`
      : '';

  let suffixPath = '';
  let suffixQs = '';
  const showingMostRead = isFirstEnlarged && mobileStyle && results && results[0] && results[0].mostRead;

  if (compactMode && results && results[0]) {
    suffixPath = `/story/${encodeURIComponent(results[0].id)}`;
  }

  if (results && results[0] && results[0].fbia) {
    suffixPath = `/story/${encodeURIComponent(results[0].id)}`;
    suffixQs = '&trendingFB=true';
  }

  if (showingMostRead && results && results[0]) {
    suffixPath = `/story/${encodeURIComponent(results[0].id)}`;
    suffixQs = '&mostRead=true';
  }

  if (relevantFirstOverride && relevantFirstOverride.id) {
    suffixPath = `/story/${encodeURIComponent(relevantFirstOverride.id)}`;
    suffixQs = '';
  }

  const abSuffix = abNumber ? `&abFromXP=${abNumber}` : '';
  const destinationDomain = environment === 'production' ? 'www.newzit.com' : 'www.newzitint.com';
  const destinationUrl = `https://${destinationDomain}${suffixPath}?ito=${itoCode}${getTabDestinationSuffix(
    currentTab
  )}${manualReferrer}${suffixQs}${abSuffix}${getTimestampQsSuffix(timestamp)}&geoFromXP=${geo}`;
  const viewMoreText = useSmallTabs ? 'View more' : 'Click here to view more';
  const viewMoreButton =
    mode === 'promo' ? (
      <div className={styles.viewMore} data-tracking-inner-text="Click here to view more >">
        Click here to view more {!compactMode && <i className={styles.viewMoreChevron} />}
      </div>
    ) : (
      <div className={styles.viewMoreWithLogo} data-tracking-inner-text={viewMoreText}>
        <img
          className={styles.viewMorelogo}
          loading="lazy"
          src={logoSrc}
          srcSet={`${logoSrc} 1x, ${logoSrcRetina} 2x`}
        />
        <div className={styles.viewMoreButton}>{viewMoreText}</div>
      </div>
    );

  const addPadding = (platform === 'mol.fbia' || platform === 'iframe') && !adSlot;

  useEffect(() => {
    if (!BIDMAX_TRACKING_ENABLED) {
      return;
    }

    trackBidmax({
      abNumber,
      channel,
      environment,
      event: 'impression',
      geoResults: geo,
      itoCode,
      platform,
      startTab,
      version
    });

    try {
      if (platform === 'mol.native.ios' || !('IntersectionObserver' in window)) {
        return;
      }

      const observer = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          trackBidmax({
            abNumber,
            channel,
            environment,
            event: 'viewable',
            geoResults: geo,
            itoCode,
            platform,
            startTab,
            version
          });
          observer.disconnect();
        }
      });

      if (appRef.current) {
        observer.observe(appRef.current);
      }

      return () => {
        observer.disconnect();
      };
    } catch (error) {
      return;
    }
  }, []);

  const handleClick = () => {
    trackBidmax({
      abNumber,
      channel,
      environment,
      event: 'click',
      geoResults: geo,
      itoCode,
      platform,
      startTab,
      version
    });
  };

  return (
    <div
      className={classnames(
        styles.previewWrapper,
        compactMode ? styles.narrow : styles[breakpoint],
        styles[mode],
        adSlot && styles[adSlot],
        mobileStyle && !compactMode && styles.mobileStyle,
        addPadding && styles.addPadding,
        darkMode && styles.darkMode,
        compactMode && styles.compactMode
      )}
      ref={appRef}
    >
      {debugAB && <div className={styles.debugInfo}>AB number: {abNumber}</div>}
      {headline && (
        <a href={destinationUrl} rel="noopener" target="_blank" className={styles.headline} onClick={handleClick}>
          {headline}
        </a>
      )}
      <div className={classnames(styles.appWrapper, useSmallTabs && styles.smallTabs)}>
        {!compactMode && (
          <div className={styles.tabContainer}>
            <div className={styles.buttonSelectors} data-current-tab={currentTab}>
              {categories
                .filter(({value}) => value !== 'newYork' || showNYTab)
                .map(({label, value}) => (
                  <button
                    className={classnames(styles.tabSelector, currentTab === value && styles.selectedTab)}
                    data-tab-name={value}
                    disabled={loading}
                    key={value}
                    onClick={() => setCurrentTab(value)}
                  >
                    {label}
                  </button>
                ))}
            </div>
          </div>
        )}
        <a
          href={destinationUrl}
          rel="noopener"
          target="_blank"
          className={styles.app}
          data-tracking-inner-text="xpmodule-news-search"
          onClick={handleClick}
        >
          {mode === 'promo' && (
            <div className={styles.heading} data-tracking-inner-text="See EVERY angle with LIVE TOP STORIES">
              {breakpoint === 'wide' && !adSlot && !compactMode && (
                <div className={styles.vanityText}>See EVERY angle with</div>
              )}
              <img className={styles.logo} loading="lazy" src={logoSrc} srcSet={`${logoSrc} 1x, ${logoSrcRetina} 2x`} />
              <div className={styles.subheading}>LIVE TOP STORIES</div>
            </div>
          )}
          <div className={styles.content}>
            {error && <div className={styles.error}>Error fetching data</div>}
            {results && results.length && (
              <div className={styles.resultList}>
                {results
                  .filter((result) => showingMostRead || !result.mostRead)
                  .slice(0, limit)
                  .map((result, index) => (
                    <ResultItem
                      trackingInnerText={`${categories.find(({value}) => value === currentTab)?.label ||
                        currentTab}: Rank ${index}: ${result.title}`}
                      key={result.id}
                      result={result}
                      breakpoint={breakpoint}
                      mode={mode}
                      adSlot={adSlot}
                      mobileStyle={mobileStyle}
                      platform={platform}
                      fullWidthImage={isFirstEnlarged && index === 0}
                      darkMode={darkMode}
                      compactMode={compactMode}
                    />
                  ))}
              </div>
            )}
            {adSlot !== 'mpu' && compactMode && results && viewMoreButton}
          </div>
          {adSlot !== 'mpu' && !compactMode && results && viewMoreButton}
        </a>
        {loading && <Spinner />}
      </div>
      {previewText && <div className={styles.previewText}>{previewText}</div>}
      {adSlot === 'mpu' && (
        <a href={destinationUrl} rel="noopener" target="_blank" onClick={handleClick}>
          {viewMoreButton}
        </a>
      )}
    </div>
  );
};
