import React from 'react';
import {render} from 'react-dom';
import {SizeMe} from 'react-sizeme';

import {App} from './components/App';
import {requestAmpResize, getPageCriteria} from './helpers';
import {getPropsFromContext} from './getPropsFromContext';

const BREAKPOINT_WIDTH = 600;

const isPlatformWideEnabled = (platform?: string) =>
  Boolean(platform && ['cc', 'mol.web.mobile', 'mol.web.desktop', 'mol.amp'].includes(platform));

const mountOnElement = async (element: HTMLElement) => {
  if (element.dataset.initialised) {
    return;
  }

  element.dataset.initialised = 'true';

  const pageCriteria = getPageCriteria();

  try {
    // Geoblock for France
    if (pageCriteria.geo && pageCriteria.geo.toLowerCase() === 'fr') {
      element.style.display = 'none';

      return;
    }
  } catch (error) {
    // Ignore
  }

  const props = await getPropsFromContext(element as HTMLDivElement);
  const {adSlot, platform} = props;

  render(
    <SizeMe>
      {({size}) => (
        <App
          {...props}
          breakpoint={
            Boolean((isPlatformWideEnabled(platform) || adSlot) && size.width && size.width > BREAKPOINT_WIDTH)
              ? 'wide'
              : 'narrow'
          }
          useSmallTabs={Boolean(size.width && size.width < 440)}
        />
      )}
    </SizeMe>,
    element
  );

  window.addEventListener('load', requestAmpResize, false);
  window.addEventListener('resize', requestAmpResize, false);
  setInterval(requestAmpResize, 1500);
};

const createApp = async () => {
  const elements = Array.from(document.querySelectorAll('[data-mol-fe-xpmodule-news-search]'));

  for (const element of elements) {
    await mountOnElement(element as HTMLElement);
  }

  setTimeout(createApp, 1000);
};

const init = () => {
  if ((document.readyState === 'interactive' && Boolean(document.body)) || document.readyState === 'complete') {
    createApp();
  } else {
    document.addEventListener('DOMContentLoaded', createApp);
  }
};

init();
