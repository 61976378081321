import axios from 'axios';

import {trackBidmax} from './bidmax';

export interface DataResult {
  id: string;
  url: string;
  title: string;
  siteIconUrl?: string;
  siteName?: string;
  resultThumbUrl?: string;
  timestamp: string;
  notification?: boolean;
  paywall?: boolean;
  mostRead?: boolean;
  fbia?: boolean;
}

export interface AbIdsResponse {
  group: number;
  molABTest: string[];
  specifiedGroup: number;
  // tslint:disable-next-line: no-any
  webUIOptions: any;
}

export type GeoCountry = 'gb' | 'us' | 'au';
export type TopStoriesCategory = 'entertainment' | 'news' | 'sport' | 'newYork';

export const getResultsByGeoAndTab = async (
  geo: GeoCountry,
  tab: TopStoriesCategory,
  apiHost: string,
  isFBIARenderer?: boolean,
  publisher?: string | null
) => {
  if (!geo || !apiHost) {
    throw new Error('geo and apiHost required');
  }

  const params: {[key: string]: string} = {key: `${tab}${geo.toUpperCase()}`};

  if (publisher) {
    params.publisher = publisher;
  }

  const response = await axios.get<{results: DataResult[]}>(
    `${apiHost}/topArticlesXP${isFBIARenderer ? 'FBIA' : ''}?${JSON.stringify(params)}`
  );

  if (!response.data || !response.data.results) {
    return null;
  }

  return response.data.results;
};

interface GeoLocationResponse {
  Country: string;
  CITY: string;
  REGION_CODE: string;
}

export const getUserCountry = async (useNewzitGeoEndpoint = false) => {
  const geoEndpoint = useNewzitGeoEndpoint
    ? 'https://www.newzit.com/locationjson.html'
    : 'https://www.dailymail.co.uk/geo/locationjson-v1.3.html';

  try {
    const response = await axios.get<GeoLocationResponse>(geoEndpoint, {
      timeout: 2000
    });

    if (!response.data || !response.data.Country) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
};

const AB_FRAME_TIMEOUT = 6000;

interface TrackingArgs {
  platform: string;
  version?: string;
  channel?: string;
}

export const loadABframe = async (environment: string, trackingArgs: TrackingArgs) => {
  const frameUrl = `https://${environment === 'production' ? 'www.newzit.com' : 'www.newzitint.com'}/setABframe.html`;

  return new Promise<string | null>((resolve, reject) => {
    let timeout: number | undefined;
    const messageHandler = (event: MessageEvent) => {
      if (event.data.scope !== 'mol-fe-xpmodule-news-search-ab') {
        return;
      }

      const abNumber = event.data.data && String(event.data.data.abNumber);

      if (abNumber && abNumber.match(/^\d+$/)) {
        window.removeEventListener('message', messageHandler);
        if (timeout) {
          clearTimeout(timeout);
        }
        resolve(abNumber);
      }
    };

    window.addEventListener('message', messageHandler);

    const iframe = document.createElement('iframe');

    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    iframe.src = frameUrl;
    document.body.appendChild(iframe);

    timeout = setTimeout(() => {
      window.removeEventListener('message', messageHandler);

      trackBidmax({
        abNumber: null,
        channel: trackingArgs.channel,
        environment,
        event: 'abtimeout',
        platform: trackingArgs.platform,
        version: trackingArgs.version || 'unknown'
      });

      reject(null);
    }, AB_FRAME_TIMEOUT);
  });
};

export const callClickUrl = (clickUrl: string) => {
  try {
    const image = new Image();

    image.src = clickUrl;
  } catch (error) {
    // Ignore errors
  }
};

export const getAbInfo = async (apiHost: string) => {
  try {
    const response = await axios.get<AbIdsResponse>(`${apiHost}/abIDs`, {
      timeout: 5000
    });

    return response.data || null;
  } catch (error) {
    return null;
  }
};

export const getRelevantFirstOverride = async (articleId: number, geo: GeoCountry, environment: string) => {
  try {
    const siteHost = environment === 'production' ? 'https://www.newzit.com' : 'https://www.newzitint.com';
    const response = await axios.get<DataResult>(`${siteHost}/topArticles/amp/${geo}/${articleId}.json`, {
      timeout: 5000
    });

    return response.data || null;
  } catch (error) {
    return null;
  }
};
