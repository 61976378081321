import React, {useState, useEffect} from 'react';
import TimeAgo, {Formatter} from 'react-timeago';
import classnames from 'classnames';

import {requestAmpResize} from '../../helpers';
import {DataResult} from '../../api';
import {Platform} from '../App';

import styles from './styles.css';

interface ResultItemProps {
  result: DataResult;
  breakpoint?: 'narrow' | 'wide';
  mode: 'promo' | 'article';
  adSlot?: 'mpu' | 'mpuSmall' | 'bellyband';
  mobileStyle?: boolean;
  platform?: Platform;
  fullWidthImage?: boolean;
  trackingInnerText: string;
  darkMode?: boolean;
  compactMode?: boolean;
}

const timeFormatter: Formatter = (value, unit) => {
  const unitStr = {
    day: 'day',
    hour: 'hr',
    minute: 'min',
    month: 'mth',
    second: 'sec',
    week: 'wk',
    year: 'yr'
  };
  const formattedUnit = `${unitStr[unit] || unit}${value > 1 ? 's' : ''}`;

  return `${value}${formattedUnit} ago`;
};

export const ResultItem = ({
  result,
  breakpoint = 'narrow',
  mode,
  adSlot,
  mobileStyle,
  platform,
  fullWidthImage,
  trackingInnerText,
  darkMode,
  compactMode
}: ResultItemProps) => {
  const {
    title,
    resultThumbUrl,
    timestamp,
    siteIconUrl,
    siteName,
    notification: breaking,
    paywall,
    mostRead,
    fbia
  } = result;
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [result]);

  return (
    <div
      className={classnames(
        styles.result,
        breaking && styles.breaking,
        compactMode ? styles.narrow : styles[breakpoint],
        styles[mode],
        adSlot && styles[adSlot],
        mobileStyle && !compactMode && styles.mobileStyle,
        platform === 'mol.native.android' && styles.android,
        fullWidthImage && styles.fullWidthImage,
        darkMode && styles.darkMode,
        compactMode && styles.compactMode
      )}
      data-tracking-inner-text={trackingInnerText}
    >
      {resultThumbUrl && (
        <div
          className={classnames(styles.thumbContainer, imageError && styles.imageError)}
          style={imageError && siteIconUrl ? {backgroundImage: `url(${siteIconUrl})`} : {}}
        >
          <div>
            <img
              alt="thumbnail"
              key={resultThumbUrl}
              loading="lazy"
              onError={() => setImageError(true)}
              onLoad={fullWidthImage ? requestAmpResize : undefined}
              src={resultThumbUrl}
              width="328"
              height="220"
            />
            {paywall && <div className={styles.paywallFlag}>PAYWALL</div>}
          </div>
        </div>
      )}
      <div className={styles.info}>
        <div className={styles.title}>{title}</div>
        <div className={styles.snippet}>
          {breaking && <div className={styles.breakingLabel}>BREAKING</div>}
          {mostRead && mobileStyle && fullWidthImage && <div className={styles.mostReadLabel}>TRENDING</div>}
          {fbia && <div className={styles.fbiaLabel}>TRENDING ON FACEBOOK</div>}
          {siteName && (
            <div className={styles.siteLink}>
              {siteIconUrl && <img alt="site icon" className={styles.favicon} loading="lazy" src={siteIconUrl} />}
              <div className={styles.snippetText}>
                <span>{siteName}</span>
                {timestamp && <span className={styles.snippetSeparator}>·</span>}
                {timestamp && <TimeAgo date={timestamp} formatter={timeFormatter} />}
              </div>
            </div>
          )}
          {!compactMode && (
            <div aria-label="see more sources" className={styles.relatedButton}>
              See more versions
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
